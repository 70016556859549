import React from 'react';
// Helper function to derive a valid ID from a given name
const deriveIdFromName = (name) => {
  return name.toLowerCase().replace(/ /g, '_');
};
const TeamMemberTile = ({name, vocation,modalTarget, }) => {

  const id = deriveIdFromName(name); // Derive the ID from the name

  // Background image style for the avatar
  const avatarStyle = {
    backgroundImage: `url(img/team/avatars/${id}.jpg)`, // Correct inline style format
  };
return(
  <div className="team__member__wrap uk-width-1-5@l uk-width-1-2@m uk-width-1-2">
    <a className="team__member" data-uk-toggle={`target: ${modalTarget}`}>
      <div className="cover__photo uk-background-cover uk-transition-toggle" data-uk-img  style={avatarStyle}>
    
        <span className="cover__link boxshadow icon icon--dots uk-transition-scale-up"></span>
      </div>
      <span className="team__name">{name}</span>
      <span className="team__vocation">{vocation}</span>
    </a>
  </div>
)};

export default TeamMemberTile;
