import React from "react";
import MusicAlbum from "./MusicAlbum";

// Data for the music section
const musicData = [
  {
    coverImg: "img/albums/bap-bw.png",
    title: "Back Alley Prophet Originals",
    year: "2023",
    tracks: [
      { title: "20 Songs", audio: "20-songs.wav" },
      { title: "Ain't Living Right", audio: "aint-living-right.wav" },
      { title: "Call it Love", audio: "call-it-love.wav" },
      { title: "Four Eyed Momma", audio: "four-eyed-momma.wav" },
      { title: "Fourteen", audio: "fourteen.wav" },
      { title: "Make a New Day", audio: "make-a-new-day.wav" },
      { title: "No Place For Me", audio: "no-place-for-me.wav" },
      { title: "Pink n Lace", audio: "pink-n-lace.wav" },
    ],
  },
  {
    coverImg: "img/albums/bap-wb.png",
    title: "Back Alley Prophet Covers Pt.1",
    year: "2023",
    tracks: [
      { title: "Back In The USSR", audio: "back-in-the-ussr.wav" },
      { title: "Can't You See", audio: "cant-you-see.wav" },
      { title: "Chain of Fools", audio: "chain-of-fools.wav" },
      { title: "Cherry Bomb", audio: "cherry-bomb.wav" },
      { title: "Drive My Car", audio: "drive-my-car.wav" },
      { title: "Feeling Alright", audio: "feeling-alright.wav" },
      {
        title: "First We Take Manhattan",
        audio: "first-we-take-manhattan.wav",
      },
      { title: "Fortunate Son", audio: "fortunate-son.wav" },
      { title: "Get Ready", audio: "get-ready.wav" },
      { title: "Hit The Road Jack", audio: "hit-the-road-jack.wav" },
      { title: "Loving You Ain't Easy", audio: "loving-you-aint-easy.wav" },
    ],
  },
  {
    coverImg: "img/albums/bap-wb.png",
    title: "Back Alley Prophet Covers Pt.2",
    year: "2023",
    tracks: [
      { title: "Mary Had A Little Lamb", audio: "mary-had-a-little-lamb.wav" },
      { title: "Money", audio: "money.wav" },
      { title: "Mustang Sally", audio: "mustang-sally.wav" },
      { title: "Old Man Down the Road", audio: "old-man-dow-the-road.wav" },
      { title: "Pink Houses", audio: "pink-houses.wav" },
      { title: "Pride & Joy", audio: "pride-and-joy.wav" },
      { title: "She's Not There", audio: "shes-not-there.wav" },
      { title: "Stand By Me", audio: "stand-by-me.wav" },
      { title: "Steamy Windows", audio: "steamy-windows.wav" },
      { title: "Tennessee Whisky", audio: "tennesse-whisky.wav" },
      { title: "Tore Down", audio: "tore-down.wav" },
    ],
  },
  // Add more albums as needed
];

const MusicSection = () => {
  return (
    <section id="music" className="music uk-section">
      <div className="container--min uk-container">
        <h2 className="uk-h2">Music</h2>
        <div className="uk-child-width-1-1 uk-child-width-1-3@m" data-uk-grid>
          {musicData.map((album, index) => (
            <div key={index} className="playlist__wrap">
              <MusicAlbum
                albumIndex={index}
                coverImg={album.coverImg}
                title={album.title}
                year={album.year}
                tracks={album.tracks}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MusicSection;
