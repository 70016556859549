import React from 'react';
import { format, parse, isPast } from 'date-fns'; // For date parsing and formatting

const createGoogleCalendarLink = (event) => {
  if (!event) {
    console.error("Event is undefined");
    return null;
  }

  const { date, end_time, venue, address, contactLink } = event;

  // Define the format of the incoming date and time (MM/DD/YYYY h:mm A)
  const dateFormat = "MM/dd/yyyy h:mm a";

  let formattedStartDate;
  let formattedEndDate;

  try {
    // Parse the date and end time from the specified format
    const parsedStartDate = parse(date, dateFormat, new Date());
    const parsedEndDate = parse(end_time, dateFormat, new Date());

    // Convert to Google Calendar date-time format
    formattedStartDate = format(parsedStartDate, "yyyyMMdd'T'HHmmss");
    formattedEndDate = format(parsedEndDate, "yyyyMMdd'T'HHmmss");
  } catch (error) {
    console.error("Error parsing date or end_time:", error);
    return null;
  }

  // Create the Google Calendar URL with appropriate parameters
  const googleCalendarUrl = new URL("https://calendar.google.com/calendar/render");
  googleCalendarUrl.searchParams.append("action", "TEMPLATE");
  googleCalendarUrl.searchParams.append("text", `Back Alley Prophet at ${venue}`); // Event title
  googleCalendarUrl.searchParams.append("dates", `${formattedStartDate}/${formattedEndDate}`); // Start and end times
  googleCalendarUrl.searchParams.append("details", `Contact: ${contactLink || 'N/A'}`); // Contact information
  googleCalendarUrl.searchParams.append("location", `${venue}, ${address || 'Location not provided'}`); // Location information

  return googleCalendarUrl.toString(); // Return the URL
};

const CalendarButton = ({ event }) => {
  const googleCalendarLink = createGoogleCalendarLink(event);
  const disabledClass = isPast(parse(event.end_time, "MM/dd/yyyy h:mm a", new Date())) ? "btn--disabled" : "";

  if (!googleCalendarLink) {
    return <span>Invalid calendar link</span>; // Display a message if there's an error
  }

  return (
    <div className="uk-container events__booking uk-width-1-2 uk-width-1-6@s">
      <a
        href={googleCalendarLink}
        target="_blank" // Opens in a new tab
        rel="noopener noreferrer"
        className={`addToCalendar uk-button btn ${disabledClass}`} // Apply the disabled class conditionally
      >
        Add to Calendar
      </a>
    </div>
  );
};

export default CalendarButton;
