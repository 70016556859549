import React from 'react';
import TeamMemberProfile from './TeamMemberProfile';
import TeamMemberTile from './TeamMemberTile';
import { teamMemberData } from './teamMemberData'; // Importing the data set

const TeamMembers = () => {
  return (
    <div>
      <section
        id="team"
        className="team uk-section uk-overflow-hidden"
        data-uk-scrollspy="cls:uk-animation-fade; offset-top: 80px; delay: 300"
      >
        <div className="container--min uk-container uk-position-relative">
          <h3 className="team__title uk-h3">
            <span className="team__title_inner">Meet the team</span>
          </h3>
          <div
            className="team__members uk-flex uk-flex-center"
            data-uk-grid
            data-uk-scrollspy="cls:uk-animation-fade; target: .team__member__wrap; delay: 300"
          >
            {teamMemberData.map((member, index) => (
              <TeamMemberTile
                key={index}
                name={member.name} // Name of the member
                vocation={member.title} // Title/vocation of the member
                modalTarget={`#${member.name.toLowerCase().replace(/ /g, '_')}`} // Ensure the modal target matches the modal's ID
              />
            ))}
          </div>
        </div>
      </section>

      {teamMemberData.map((member, index) => (
        <TeamMemberProfile
          key={index}
          name={member.name}
          title={member.subtitles}
          description={member.description}
          quote={member.quote}
          socialLinks={member.socialLinks}
        />
      ))}
    </div>
  );
};

export default TeamMembers;
