import React from "react";
import { format, isPast, parse } from "date-fns";
import CalendarButton from "./CalendarButton";

const ShowItem = ({ event }) => {
  const { date: rawDate, venue, address, contactLink } = event;

  // Parse the date string into a valid Date object
  let eventDate;
  try {
    // This pattern matches the provided format "MM/DD/YYYY h:mm A"
    eventDate = parse(rawDate, "MM/dd/yyyy h:mm a", new Date());
  } catch (error) {
    console.error("Error parsing custom date:", error);
    return <div>Error: Invalid date format.</div>;
  }

  // Format the components from the Date object
  const month = format(eventDate, "MMM"); // Abbreviated month (e.g., Jan, Feb)
  const day = format(eventDate, "dd"); // Two-digit day (01, 02, ...)
  const year = format(eventDate, "yyyy"); // Four-digit year
  const time = format(eventDate, "hh:mm a"); // 12-hour time with AM/PM

  // Check if the event has passed
  const isEventPast = isPast(eventDate);

  // Conditionally add btn--disabled if the event has passed
  const listItemClass = isEventPast ? "btn--disabled" : "";

  return (
    <div className={`uk-flex list__item ${listItemClass}`}>
      <div className="events__date date uk-width-1-2 uk-width-1-6@s ">

          <span className="events__month">{month}</span>

          <span className="events__day">{day}</span>
          <br/>
          <span className="events__time">{time}</span>
          <span className=" hide events__year">{year}</span>
  
      </div>

      <div className="date uk-width-1-2">
        <div className="events__location uk-width-expand">
          <span className="events__venue">{venue}</span>
          <span className="events__address">{address}</span>
        </div>
      </div>
      <CalendarButton event={event} />

      <div className="events__booking uk-width-1-2 uk-width-1-6@s">
        {contactLink && !isEventPast ? (
          <a href={contactLink} className="btn uk-button">
            Contact
          </a>
        ) : (
          <span className="btn uk-button btn--disabled">Past Event</span>
        )}
      </div>
    </div>
  );
};

export default ShowItem;