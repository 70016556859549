import $ from "jquery";

// Utility function to format time in minutes:seconds
function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0");
  const sec = Math.floor(seconds % 60)
    .toString()
    .padStart(2, "0");
  return `${minutes}:${sec}`;
}

$(function () {
  const audioElements = $("audio"); // All audio elements
  const playPauseButtons = $(".audio-play-pause"); // All play/pause buttons

  // Function to pause all other audio elements except the one specified
  function pauseAllExcept(exceptAudio) {
    audioElements.each(function () {
      const audio = $(this)[0];
      const audioId = $(this).attr("id");

      if (audio !== exceptAudio) {
        audio.pause();
        const playPauseButton = playPauseButtons.filter(
          `[data-audio-id="${audioId}"]`
        );
        playPauseButton.removeClass("icon--pause").addClass("icon--play");
      }
    });
  }

  // Track the playtime of each audio element
  function trackPlaytime(audioElement, playTimeElement) {
    let playtimeInterval;

    // Start tracking playtime when the audio plays
    audioElement.addEventListener("play", function () {
      clearInterval(playtimeInterval); // Clear any previous interval
      playtimeInterval = setInterval(() => {
        const currentTime = audioElement.currentTime;
        playTimeElement.text(formatTime(currentTime));
      }, 1000); // Update every second
    });

    // Stop tracking playtime when the audio is paused or ends
    const stopTracking = () => clearInterval(playtimeInterval);

    audioElement.addEventListener("pause", stopTracking);
    audioElement.addEventListener("ended", stopTracking);
  }

  // Event listener to update play/pause buttons and track playtime
  audioElements.each(function () {
    const audioElement = $(this)[0];
    const audioId = $(this).attr("id"); // ID of the audio element
    const playPauseButton = playPauseButtons.filter(
      `[data-audio-id="${audioId}"]`
    ); // Corresponding play/pause button

    // Get the playtime display element
    const playTimeElement = $(this).siblings(".audio-playback-time");

    // Set up event listeners for play/pause/ended
    audioElement.addEventListener("play", function () {
      pauseAllExcept(audioElement); // Pause all other audio elements
      playPauseButton.removeClass("icon--play").addClass("icon--pause");
    });

    audioElement.addEventListener("pause", function () {
      playPauseButton.removeClass("icon--pause").addClass("icon--play");
    });

    audioElement.addEventListener("ended", function () {
      playPauseButton.removeClass("icon--pause").addClass("icon--play");
      playTimeElement.text("00:00"); // Reset playtime on end
    });

    // Track playtime
    trackPlaytime(audioElement, playTimeElement);
  });

  // Handle play/pause button click events
  playPauseButtons.on("click", function () {
    const audioId = $(this).attr("data-audio-id");
    const audioElement = $(`#${audioId}`)[0]; // Get the audio element by ID

    if (audioElement.paused) {
      pauseAllExcept(audioElement); // Pause other audio elements
      audioElement.play(); // Play the current one
    } else {
      audioElement.pause(); // Pause the current one
    }
  });
});
