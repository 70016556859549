import "./App.css";

import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import AboutUs from "./Components/AboutUs";
import Videos from "./Components/Videos";
import EventList from "./Components/Shows/EventList";
import TeamMembers from "./Components/Members/TeamMembers";
import MusicSection from "./Components/Music/MusicSection";
import Contact from "./Components/Contact";
import References from "./Components/References";

const App = () => {
  return (
    <div className="App">
      <Header />
      <AboutUs />
      <TeamMembers />
      <MusicSection />
      <Videos />
      <EventList />
      <References />
      <Contact />
      <Footer />

    </div>
  );
};

export default App;
