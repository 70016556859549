import React from "react";
import MusicTrack from "./MusicTrack";

const MusicAlbum = ({ albumIndex, coverImg, title, year, tracks }) => {
  return (
    <div className="playlist bg-dark--1 uk-active">
      <div className="albums albums--playlist albums__item">
        <div className="albums__cover">
          <img src={coverImg} alt={title} data-uk-img />
        </div>
        <div className="album__details">
          <span className="albums__title">{title}</span>
          <span className="albums__year">{year}</span>
        </div>
      </div>
      <ul className="tracks list--striped">
        {tracks.map((track, index) => (
          <MusicTrack
            albumIndex={albumIndex}
            trackIndex={index}
            key={index}
            audioSrc={track.audio}
            title={track.title}
          />
        ))}
      </ul>
    </div>
  );
};

export default MusicAlbum;
