import React, { useEffect, useState } from "react";
import axios from "axios";
import ShowItem from './ShowItem';
const EventList = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Replace with your own Google Sheets ID and Sheet Name
        const sheetId = "1ElCurAUvGDkKL7uVQG9zpeC1-ROOmJZyVLaWkQ7h4vg"; 
        const sheetName = "Sheet 1";
        const response = await axios.get(
          `https://opensheet.vercel.app/${sheetId}/${sheetName}`
        );
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events data:", error);
      }
    };

    fetchEvents();
  }, []);

  return (   
     <section id="shows" className="events uk-section" data-uk-scrollspy="cls:uk-animation-fade; offset-top: 80px; delay: 300">
  <div className="container--min uk-container">
    <h2 className="uk-h2 uk-margin-bottom">Shows</h2>
    <div className="events__table list--striped bg-dark--1">
      {events.map((event, index) => (
        <ShowItem key={index} event={event} />
      ))}
    </div>
  </div>
  </section>

  );
};

export default EventList;
