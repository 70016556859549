import React, { useState, useRef, useEffect } from "react";

const MusicTrack = ({ albumIndex, audioSrc, title, trackIndex }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Ensure the play/pause state is updated when audio ends
  useEffect(() => {
    const currentAudio = audioRef.current;

    const onAudioEnd = () => {
      setIsPlaying(false);
    };

    if (currentAudio) {
      currentAudio.addEventListener("ended", onAudioEnd);
    }

    return () => {
      if (currentAudio) {
        currentAudio.removeEventListener("ended", onAudioEnd);
      }
    };
  }, []);

  return (
    <li
      className={`list__item ${isPlaying ? "is-playing" : ""}`}
      onClick={handlePlayPause} // Clicking anywhere in the list item toggles play/pause
      data-audio={audioSrc}
    >
      <button
        id={audioRef.current ? audioRef.current.id + "-btn" : ""}
        type="button"
        className={`icon icon--play-pause audio-play-pause js-audio-play ${
          isPlaying ? "icon--pause" : "icon--play"
        }`}
        data-audio-id={`track-${albumIndex}-${trackIndex}`}
      ></button>
      <span className="tracks__title">{title}</span>
      <span className="js-audio-time tracks__time audio-playback-time"></span>

      <audio
        id={`track-${albumIndex}-${trackIndex}`}
        ref={audioRef}
        src={`/audio/${audioSrc}`}
      />
    </li>
  );
};

export default MusicTrack;
