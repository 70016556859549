import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-dark--1 uk-section uk-section-small">
      <div className="container--min uk-container">
        <div className="uk-flex uk-flex-between uk-child-width-auto" data-uk-grid>
          <ul className="nav nav--footer js-scrollspy-nav uk-flex uk-flex-wrap">
            <li><a href="#about_us">About Us</a></li>
            <li><a href="#music">Music</a></li>
            <li><a href="#shows">Shows</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
        <div className="social social--footer ">
          <ul className="social__list uk-list uk-align-center ">
            <li><a href="https://www.youtube.com/@BackAlleyProphet" target="_blank"><span className="fab fa-youtube"></span></a></li>
            <li><a href="https://www.facebook.com/groups/backalleyprophetband" target="_blank"><span className="fab fa-facebook"></span></a></li>

          </ul>
        </div>
        <p className="footer__copyrights">
          Special thanks<br></br>
          &copy;2024. <a href="https://backalleyprophet.com/millsdylan" target="_blank"><span className="fabr">Dylan Mills</span></a><br></br>
        </p>
        
      </div>
      <a href="#" className="footer__totop" data-uk-totop data-uk-scroll></a>
    </footer>
  );
};

export default Footer;
