import React from 'react';

const References = () => {
  return (
    <section id="references" className="hide events uk-section uk-scrollspy-inview">
      <div id="michele-bolton" >
        <div className="">
          <div className="modal__cover uk-cover-container">
            <img
              src="img/team/covers/alley.jpg"
              data-src="img/team/covers/alley.jpg"
              alt=""
              data-uk-cover
            />
          </div>
          <div className="modal__content">
            <div
              className="modal__avatar boxshadow uk-background-cover"
              data-src="img/team/avatars/michele.jpg"
            //   style={"background-image : url(`img/team/avatars/michele.jpg`)"}
              data-uk-img
            ></div>
            <h3 className="modal__profile__title uk-modal-title uk-text-center">
              Michèle Bolton
            </h3>
            <div className="modal__profile__subtitle uk-text-center">
              OpenStudio Art Cafe Owner
            </div>
            <p className="uk-text-left">
              <span className="quote">
                {" "}
                “This recently formed band do not cease to amaze on how solid
                their sound has become in the short time they’ve played
                together. A true testament to their individual talents and
                commitment to a unique sound. A repertoire that hints ’60
                psychedelia through to early 2000’s groove and the many waves
                between, they manage to make each sound their own. Paul and Mary
                lead with energized originals and covers, together with
                outstanding bandmates, Dennis, Jeff, and James providing solid
                sound and stage presence. It’s no wonder they’re filling venues
                across Durham.” – Michèle Bolton, OpenStudio Art Cafe
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default References;
