import React from 'react';
import Navigation from './Navigation';

const Header = () => {
  return (
    <header className="panel js-panel hide">
      <div className="container--panel uk-container uk-height-1-1">
        <div className="uk-flex uk-flex-middle uk-height-1-1" data-uk-grid>
          {/* Logo */}
          <div className="uk-width-1-3 uk-width-1-6@m">

          </div>
          {/* Navigation */}
          <div className="uk-width-2-3 uk-visible@m">
            <Navigation />
          </div>
          {/* Mobile Menu */}
          <div className="uk-width-1-3 uk-hidden@m uk-text-right">
            <a href="#" data-uk-toggle="target: #offcanvas-nav">Menu</a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
