import React from "react";

// Helper function to derive a valid ID from a given name
const deriveIdFromName = (name) => {
  return name.toLowerCase().replace(/ /g, "_");
};

const TeamMemberProfile = ({
  name,
  title,
  description,
  quote,
  socialLinks,
}) => {
  const id = deriveIdFromName(name); // Derive the ID from the name

  // Background image style for the avatar
  const avatarStyle = {
    backgroundImage: `url(img/team/avatars/${id}.jpg)`, // Correct inline style format
  };

  return (
    <div id={id} data-uk-modal>
      <div className="modal modal__profile uk-modal-dialog uk-margin-auto-vertical">
        <div className="modal__cover uk-cover-container">
          <img
            src="img/team/covers/alley.jpg" // Set cover image
            alt="alleyway background"
            data-uk-cover
          />
        </div>
        <div className="modal__content">
          <div
            className="modal__avatar boxshadow uk-background-cover"
            style={avatarStyle} // Set background-image using the defined style
            data-uk-img
          ></div>
          <h3 className="modal__profile__title uk-modal-title uk-text-center">
            {name}
          </h3>
          <div className="modal__profile__subtitle uk-text-center">{title}</div>
          <p className="uk-text-left">
            {description}
            <br />
            {quote && <span className="quote">{quote}</span>}
          </p>
          <div className="social social--modal">
            <ul className="social__list uk-list uk-align-center">
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    <span className={link.icon}></span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <button
          className="modal__close uk-modal-close-outside"
          type="button"
          data-uk-close
        ></button>
      </div>
    </div>
  );
};

export default TeamMemberProfile;
