export const teamMemberData = [
  {
    name: "Mary Crover",
    title: "Lead Singer and Songwriter",
    subtitles:
      "Lead Singer, Lead Songwriter, Performer, Recording Artist, Guitarist, Keys, Djimbe",
    description: `Mary has been writing poetry since her early teens and switched to songwriting after picking up the guitar over 30 years ago. Her performance career started at that same time, hosting open jams and singing in musical comedy shows.`,
    quote: `"Arriving at a place of belonging, living creatively with raw authenticity can happen whenever you are ready to break out... but right here and now is where this child thrives."`,
    socialLinks: [
      // { url: '#', icon: 'fab fa-facebook-f' },
      // { url: '#', icon: 'fab fa-instagram' },
      // { url: '#', icon: 'fab fa-twitter' },
    ],
  },
  {
    name: "James Hunter",
    title: "Drummer",
    subtitles: "Drummer",
    description: `James joined Back Alley Prophet in 2023, and frequently performs with other bands in the greater Toronto area.`,
    socialLinks: [
      // { url: '#', icon: 'fab fa-facebook-f' },
      // { url: '#', icon: 'fab fa-instagram' },
      // { url: '#', icon: 'fab fa-twitter' },
    ],
  },
  {
    name: "Paul Lalla",
    title: "Lead Guitarist",
    subtitles: "Guitarist, Singer, Songwriter, Recording Artist",
    description: `Paul (A.K.A. Rusty Cage) started playing guitar at age 12 and never looked back. "I’m still writing and performing and having fun!"`,
    quote: `"If you can't laugh as hard as you cry, what is life!!"`,
    socialLinks: [
      // { url: '#', icon: 'fab fa-facebook-f' },
      // { url: '#', icon: 'fab fa-instagram' },
      // { url: '#', icon: 'fab fa-twitter' },
    ],
  },
  {
    name: "Jeff Stones",
    title: "Bass Guitar",
    subtitles: "Bass, Guitarist, Singer, Songwriter, Recording Artist",
    description: `Singer, songwriter, and recording artist Jeff’s initial inclination at age 14 was towards pop, rock, blues, and country music. He eventually moved on to playing the bass and electric guitar.`,
    quote: `"Music has been a source of enjoyment from childhood."`,
    socialLinks: [
      // { url: '#', icon: 'fab fa-facebook-f' },
      // { url: '#', icon: 'fab fa-instagram' },
      // { url: '#', icon: 'fab fa-twitter' },
    ],
  },
  {
    name: "Dennis Hebert",
    title: "Guitarist, Singer",
    subtitles: "Rhythm Guitarist, Singer, Drummer",
    description: `Dennis came from a family of musicians and has been disturbing the peace since age 14. He eventually led a Country band, "Mustang", as a powerhouse lead singer for over ten years.`,
    quote: `"Coming full circle with Back Alley Prophet is like a homecoming."`,
    socialLinks: [
      // { url: '#', icon: 'fab fa-facebook-f' },
      // { url: '#', icon: 'fab fa-instagram' },
      // { url: '#', icon: 'fab fa-twitter' },
    ],
  },
];
