import React from "react";

const Contact = () => {
  return (
    <section
      id="contact"
      className="contacts uk-section"
      data-uk-scrollspy="cls:uk-animation-fade; offset-top: 80px; delay: 300"
    >
      <div className="container--min uk-container">
        <h2 className="uk-h2">Contact Us!</h2>
        <div data-uk-grid>
          <div className="uk-width-1-3@m">
            <h3 className="uk-h3">Organization of concerts</h3>
            <div data-uk-grid>
              <p>
                Along with performing at the usual gigs and festivals, Back
                Alley Prophet, extends itself to perform at fundraisers and
                charitable events to support the community we share. Please
                contact us for support for your charitable event
              </p>

              <p className="contacts__info uk-width-1-1 uk-width-1-2@s uk-width-1-1@m">
                <strong className="contacts__subtitle">E-mail:</strong>
                <br />
                <a href="mailto:backalleyprophetmusic@gmail.com">
                  backalleyprophetmusic@gmail.com
                </a>
              </p>
            </div>
          </div>
          <div className="uk-width-2-3@m">
            <div className="uk-card bg-dark--1">
              <div className="contacts__form uk-card-body">
                <h3 className="uk-h3">Get in touch</h3>

                <form
                  action="php/contact-form-process.php"
                  className="php-email-form"
                  method="post"
                  role="form"
                  data-uk-grid
                >
                  <div className="uk-width-1-2@s">
                    <input
                      name="Name"
                      className="uk-input"
                      type="text"
                      id="Name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="uk-width-1-2@s">
                    <input
                      name="Email"
                      className="uk-input"
                      type="email"
                      placeholder="E-mail*"
                      required
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <textarea
                      name="Message"
                      className="uk-textarea"
                      rows="8"
                      placeholder="Message*"
                      required
                    ></textarea>
                  </div>
                  <div className="uk-width-1-1 uk-text-center">
                    <p className="form__respond js-respond"></p>
                    <input className="btn" type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
