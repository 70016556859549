import React from "react";

const AboutUs = () => {
  return (
    <section id="about_us" className="about intro uk-margin-large-bottom">
      <div className="container--min uk-container uk-position-relative">
        <div className="uk-grid-collapse" data-uk-grid>
          <div className="intro__sidebar uk-width-1-4@m uk-visible@m uk-position-relative">


            <ul className="nav nav--intro uk-list js-scrollspy-nav">
              <li>
                <a href="#about_us">About us</a>
              </li>
              <li>
                <a href="#music">Music</a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@BackAlleyProphet"
                  target="_blank"
                >
                  Videos
                </a>
              </li>
              <li>
                <a href="#shows">Shows</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/groups/backalleyprophetband"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
            </ul>

            <div className="social__wrapper hide">
              <div className="social social--intro">
                <h6 className="uk-h4 social__title c-dark--4">Social media</h6>
                <ul className="social__list uk-list uk-align-center "></ul>
              </div>
            </div>
          </div>
          <div className="abt-mobile">
            {" "}
           
          </div>

          <div className="uk-width-3-4@m about__info">
            <h2 className="uk-text-right uk-h2">About🔮</h2>
            <div className="uk-text-right">
              <p>
                Back Alley Prophet, a band from the Toronto area, captures
                audiences with driving energy performing both original music and
                covers. With a ‘Canadian Southern Rock’ feel, their music is a
                blend of many genres, although Classic Rock/Blues is the
                predominant force.<br></br>
              </p>

              <div className="uk-text-right uk-margin-large-left">
                <p>
                  Lead singer,{" "}
                  <a
                    className="uk-text-warning"
                    data-uk-toggle="target: #mary_crover"
                  >
                    Mary Crover
                  </a>{" "}
                  brings a dynamic performance, belting out powerful vocals that
                  are matched by the intensity of their lead guitarist,{" "}
                  <a
                    className="uk-text-warning"
                    data-uk-toggle="target: #paul_lalla"
                  >
                    Paul Lalla
                  </a>
                  .
                </p>
              </div>
              <div className="uk-text-right uk-margin-large-left">
                <p>
                  Sharing the lead vocals and providing compelling harmonies,{" "}
                  <a
                    className="uk-text-warning"
                    data-uk-toggle="target: #dennis_hebert"
                  >
                    Dennis Hebert
                  </a>
                  , former lead singer of ‘Mustang’, drives the band’s sound to
                  a new level.
                  <br></br>
                </p>
              </div>
            </div>
            <div className="uk-text-right uk-margin-large-left">
              <p>
                The rhythm section, made up of rhythm guitarist{" "}
                <a
                  className="uk-text-warning"
                  data-uk-toggle="target: #dennis_hebert"
                >
                  Dennis Hebert
                </a>
                , bassist{" "}
                <a
                  className="uk-text-warning"
                  data-uk-toggle="target: #jeff_stones"
                >
                  Jeff Stones
                </a>{" "}
                and percussionist{" "}
                <a
                  className="uk-text-warning"
                  data-uk-toggle="target: #james_hunter"
                >
                  James Hunter
                </a>{" "}
                provide a strong foundation for their diversified sound.
              </p>
            </div>

            <div className="uk-text-right">
              <p>
                With their catalogue of unique originals, Back Alley Prophet is
                sure to get any crowd on their feet dancing or sitting back
                listening to the relatable stories they write.
              </p>
            </div>

            <div className="uk-text-right">
              <p>
                Back Alley Prophet looks forward to seeing you at their next
                performance where we will share our collection of songs with
                you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
